<template>
    <!-- 单品作品 -->
    <div class="box">
        <div class="box-content" ref="load">
            <div v-if="list && list.length>0" class="list flex">
                <div class="list-item" v-for="(item,idx) in list" :key="idx">
                    <div @click="gotoDetail(item.id)" style="width:210px;height:210px;cursor:pointer;">
                        <div v-if="item.photo_render && item.photo_render.length>0" class="bg_img"
                        :style="{backgroundImage: 'url(' + imgUrl+item.photo_render[0]+'!width_250px' + ')'}"></div>
                    </div>
                    <div class="detail">
                        <div class="item-price">￥{{item.price | priceFilt}}</div>
                        <div @click="gotoDetail(item.id)" class="item-name ellipsis">{{item.name}}</div>
                        <div class="item-series">
                            <span v-if="item.style">{{item.style.name}}</span>
                            <span v-if="item.style && item.cat" style="margin:0 4px"> | </span>
                            <span v-if="item.cat">{{item.cat.name}}</span>
                        </div>
                        <!-- <div style="font-size:12px;margin-bottom:8px;" class="flex flex-align-center">
                            <div style="margin-right: 15px;">已有{{item.design_market_n_visited}}人查看</div>
                            <div><el-button @click.stop="gotoSign(item)" size="mini" type="primary">我要购买</el-button></div>
                        </div> -->
                        <div class="item-avatar flex flex-align-center">
                            <div style="width:24px;height:24px;border-radius:50%;overflow:hidden;margin-right:8px;">
                                <div v-if="item.design_comp && item.design_comp.logo" class="bg_img"
                                    :style="{backgroundImage: 'url(' + imgUrl+item.design_comp.logo + ')'}"></div>
                                <div v-else class="bg_img"
                                :style="{backgroundImage: 'url(' + staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px' + ')'}"></div>
                            </div>
                            <div class="flex-1 ellipsis" v-if="item.design_comp && item.design_comp.name">{{item.design_comp.name}}</div>
                        </div>

                    </div>
                </div>
            </div>
            <div v-if="list && list.length==0" class="flex flex-center" style="text-align:center;height:400px;">
                <div>
                    <img style="width:110px" :src="staticUrl+'/design/placeholder/meiyougengduo.png'" alt="">
                    <div style="font-size:16px;color:#808080;padding:15px 0;">暂无相关数据</div>
                </div>
            </div>
        </div>
        <div style="padding:10px 0;">
            <el-pagination background @current-change="currentChange"
                layout="prev, pager, next"
                :current-page.sync="opt.page"
                :page-size="opt.pagesize"
                :total="total"></el-pagination>
        </div>
    </div>
</template>

<script>
    import { IMG_URL_PRE,STATIC_URL_PRE } from '@/config';
    import { marketListProduct } from '@/service/factory';
    export default {
        components: {},
        props:['query'],
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                staticUrl:STATIC_URL_PRE,
                popVisible: false,
                popTitle: null,
                opt: {
                    k: "", // 搜索关键字
                    style_no: null, // 风格编号列表
                    cat_id: null, // 类目id列表
                    price_range: ["",""], // 价格范围，数组两个元素表示价格高低范围，数组只有一个元素表示要筛选大于等于这个值的范围
                    sort_by: "", // 默认按发布时间排序，可以传price按价格排序
                    sort_order: "", // 默认降序，传1表示升序
                    page: 1,
                    pagesize: 20,
                },
                total:0,
                list: null,
            }
        },
        created() {
            if (this.query.k) {
                this.opt.k = this.query.k;
            }
            if (this.query.style_no) {
                this.opt.style_no = [this.query.style_no];
            }
            if (this.query.cat_id) {
                this.opt.cat_id = [this.query.cat_id];
            }
            if (this.query.price_range) {
                this.opt.price_range = this.query.price_range;
            }
            if (this.query.sort_by) {
                this.opt.sort_by = this.query.sort_by;
            }
            if (this.query.sort_order) {
                this.opt.sort_order = this.query.sort_order;
            }
            this.refreshItems();
        },
        mounted() {},
        methods: {
            gotolink(path){
                this.$router.push(path);
            },
            gotoDetail(id) {
                this.$router.push("/factory/market/single/detail/"+id);
            },
            currentChange(e) { // 监听分页
                this.opt.page = e;
                this.refreshItems();
            },
            refreshItems() {
                console.log('refreshItems');
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                    target:this.$refs.load
                });
                marketListProduct(this.opt).then(rst => {
                    this.total=rst.total;
                    this.list = rst.list;
                    console.log(this.list);
                    _loading.close();
                }).catch(err => {
                    _loading.close();
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped src="../../style.css"></style>
<style scoped lang="less">
.box {
    height:100%;
    padding: 10px 20px;
}
.box-head {
    padding-bottom: 10px;
}
.box-content {
    min-height: 400px;
    overflow: auto;
}
.list-item{
    width: 212px;
    // height: 361px;
    overflow: hidden;
    border:1px solid #E6E6E6;
    margin: 10px;
    // transform: scale(1);
    // transition: all 0.3s;
    &:hover {
        // border: 1px solid rgba(80, 116, 238, 1);
        // transform: scale(1.064);
    }
    .detail {
        padding: 8px 12px 12px;
        position: relative;
        font-size: 14px;
        color: #808080;
    }
    .item-name {
        font-size: 16px;
        font-weight: bold;
        color: #292929;
        line-height:22px;
        margin-bottom: 4px;
        cursor:pointer;
    }
    .item-series {
        font-size: 12px;
        color: #B3B3B3;
        line-height: 16px;
        margin-bottom: 12px;
    }
    .item-price {
        font-size: 18px;
        color: #EE1818;
        line-height: 22px;
        margin-bottom: 8px;
    }
    .item-avatar {
        border-top: 1px solid #E6E6E6;
        padding-top: 8px;
    }
}
</style>